<template>
  <div
    class="relative flex size-full flex-col overflow-hidden rounded-xl bg-main-150 transition-all duration-300 aspect-square"
    :class="[
      className,
      gameRef?.can_play == PLAY_STATUS.CAN_PLAY
        ? 'hover:-translate-y-2'
        : 'brightness-75',
    ]"
  >
    <NuxtLink
      :to="
        gameRef?.can_play == PLAY_STATUS.CAN_PLAY
          ? ROUTES_NAME.PLAY_GAME(gameRef?.code)
          : ''
      "
      class="size-full flex-1 overflow-hidden"
      :class="linkClass"
    >
      <NuxtImg
        v-if="!isError"
        :key="game?.url_thumb"
        loading="lazy"
        :src="game?.url_thumb || game?.game_image"
        class="size-full object-cover"
        alt="game-title"
        height="100%"
        width="100%"
        quality="80"
        @error="(event) => handleError(event)"
        :class="imageClass"
      />
      <img
        src="/images/no_image.svg"
        v-else
        class="size-full object-cover"
        alt="game-title"
        loading="lazy"
        :class="imageClass"
      />
    </NuxtLink>
    <div
      v-if="gameRef?.provider_information?.name || gameRef?.provider"
      class="box-border flex h-8 flex-shrink-0 cursor-pointer items-center justify-center truncate px-px text-center text-sm font-medium md:h-11 lg:!px-3 text-white"
    >
      {{ gameRef?.provider_information?.name || gameRef?.provider }}
    </div>
    <Heart
      v-if="token"
      @click="handleLike($event)"
      class="absolute right-3 top-3 cursor-pointer"
      :class="{ heart__active: liked }"
    />
    <slot />
    <v-menu
      activator="parent"
      :disabled="gameRef?.can_play == PLAY_STATUS.CAN_PLAY"
      content-class="play-game-tooltip"
      :open-on-click="isMobile()"
      location="top center"
      open-on-hover
      :offset="[10,0]"
    >
      <block-game-tooltip :can_play="gameRef?.can_play" />
    </v-menu>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import BlockGameTooltip from "~/components/BlockGameTooltip.vue";
import Heart from "~/components/icon/Heart.vue";
import { PLAY_STATUS, PROVIDER_TYPE_FILTER } from "~/config/enum";
import { ROUTES_NAME } from "~/config/router";
import { useGameStore } from "~/stores/gameStore";
import useIsMobile from "~/uses/fetchMobile";

type Props = {
  game: any;
  slug: string;
  refreshProvider: boolean;
  className: string;
  imageClass: string;
  linkClass: string;
};
const props = defineProps<Partial<Props>>();

const { isMobile } = useIsMobile();
const token = useCustomCookie("token");
const gameStore = useGameStore();
const { listGameByCategory } = storeToRefs(gameStore);
const { getListProvidersByType } = gameStore;

const liked = ref(false);

const gameRef = computed(() => {
  if (!props.slug) {
    // If slug is null or undefined, return the passed game directly
    return props.game;
  }
  const itemFind = listGameByCategory.value[props.slug]?.listGame?.games?.find(
    (game: any) => game.id === props?.game?.id
  );
  if (itemFind) return itemFind;
  return props.game;
});

watch(
  () => gameRef.value.is_favorite,
  (isLiked) => {
    liked.value = isLiked;
  },
  { immediate: true }
);
const handleLike = async (event: MouseEvent) => {
  const newLike = !liked.value;
  event.stopPropagation();
  //handle like
  if (!props.slug) {
    await gameStore.likeGamePlayGame(gameRef.value.code, newLike).then(() => {
      liked.value = newLike;
    });
    gameRef.value.is_favorite = !gameRef.value.is_favorite; // Directly modify the game object
    return;
  }
  await gameStore.likeGame(props.slug, gameRef.value.code, newLike).then(() => {
    liked.value = newLike;
  });
  if (props.refreshProvider) {
    await getListProvidersByType(PROVIDER_TYPE_FILTER.TYPE_FAVORITES);
  }
};
const isError = ref(false);
const handleError = () => {
  isError.value = true;
};
</script>
<style scoped lang="scss">
:deep(.v-img__img--preload) {
  filter: none;
}
</style>
